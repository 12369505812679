@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@layer base {
  body {
    @apply text-tertiary bg-white dark:bg-gray-900;
  }
}
/* body {
  background-color: white !important;
} */
@layer components {
  .cms-base-input {
    @apply bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary block max-w-2xl p-2.5;
  }

  .cms-base-error-input {
    @apply bg-gray-50 border border-red-800 text-gray-900 text-sm rounded-lg focus:ring-red-800 focus:border-red-800 block max-w-2xl p-2.5;
  }

  .cms-base-label {
    @apply block mb-2 text-sm font-medium text-white;
  }

  .link-overlay {
    @apply absolute inset-0 -indent-[99999px] z-10;
  }

  .base-input {
    @apply bg-gray-100 border border-transparent rounded-md focus:ring-primary focus:border-primary block p-2.5;
  }

  .base-label {
    @apply block mb-2 font-bold;
  }
}
.Toastify__toast--error {
  border: 1px solid #880c55;
  color: #880c55 !important;
  background: #ffff !important;
}
.Toastify__toast--error::after {
  position: absolute;
  color: #880c55 !important;
  font-size: 15px;
  font-weight: 700;
  left: 265px;
  padding-top: 14px !important;
}
.Toastify__toast--error::before {
  position: relative;
  color: #880c55 !important;
  z-index: 100000;
  left: 12px;
  top: 6px;
}

.Toastify__toast--success {
  border: 1px solid #3a9ea5 !important;
  background: #880c55 !important;
  color: #ffff !important;
}
.Toastify__toast--success::before {
  position: relative;
  z-index: 100000;
  left: 12px;
  color: #ffff !important;

  top: 6px;
}
.Toastify__toast--success::after {
  position: absolute;
  font-size: 15px;
  color: #ffff !important;
  font-weight: 700;
  left: 265px;
  padding-top: 14px !important;
}

.Toastify__toast-body {
  font-size: 18px;
  padding-left: 20px;
  line-height: 20px;
  padding: 0px;
  padding-top: 25px;
  width: 100%;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  margin-left: 25px !important;
}
