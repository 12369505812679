.react-multi-carousel-item {
  width: 21rem !important;
  margin-right: 12px;
}

.react-multiple-carousel__arrow {
  background-color: rgba(0, 0, 0, 0.432) !important;
}

/* button,
[type='button'],
[type='reset'],
[type='submit'] {

     background-color: rgba(0, 0, 0, 0.432) !important; 
     background-color: none !important; 
} */
