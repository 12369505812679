from animate.css .animate__animated {
  -webkit-animation-duration: 300ms;
  animation-duration: 300ms;

  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

.rts___tabs {
  padding: 0;
  border-bottom: 2px;
}

.rts___tab {
  margin: 0;
  position: relative;
}

.rts___nav___btn svg {
  max-width: unset;
}

.rts___btn {
  border-radius: unset !important;
  border: none !important;
  padding: 10px 20px !important;
}

.rts___nav___btn {
  padding: 10px 10px !important;
}

.rts___tab::after {
  content: "";
  margin: auto;
  height: 3px;
  background: transparent;
  transition:
    width 0.5s ease,
    background-color 0.5s ease;
  width: 0;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.rts___tab___selected {
  color: #000 !important;
  position: relative;
  width: 100%;
  background: transparent;
  background-color: none !important;
  box-shadow: none !important;
  box-shadow: none;
}

.rts___tab___selected::after {
  background: #880C55!important;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
}

.rts___nav___btn:hover {
  background-color: unset !important;
}

.rts___nav___btn:hover>svg {
  stroke: rgba(0, 0, 0, 0.6) !important;
}

@media (max-width: 991.98px) {
  .rts___tabs___container {
    padding: 0;
  }
}

@media (max-width: 767.98px) {
  .rts___tab {
    padding: 5px;
  }
}